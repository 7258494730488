const actions = {
  login: 'LOGIN',
  signUp: 'SIGN_UP',
  getMyProfile: 'GET_MY_PROFILE',
  editProfile: 'EDIT_PROFILE',
  attachPaymentMethod: 'ATTACH_PAYMENT_METHOD',
  getPaymentMethods: 'GET_PAYMENT_METHODS',
  createPaymentIntent: 'CREATE_PAYMENT_INTENT',
  confirmPaymentIntent: 'CONFIRM_PAYMENT_INTENT',
  addEArt: 'ADDEART',
  addGallery: 'ADDGALLERY',
  getGalleries: 'GETGALLERiES',
  getOwnedEarts: 'GETOWNEDEARTS',
  uploadProfileImage: 'UPLOADPROFILEIMAGE',
  editEart: 'EDITEART',
  getEart: 'GETEART',
  postForSale: 'POSTFORSALE',
  unlistEart: 'UNLIST',
  getAllEarts: 'GETALLEARTS',
  createEdition: 'CREATEEDITION',
  getEartEditions: 'GETEARTEDITIONS',
  changeOwner: 'CHANGEOWNER',
  createCopyEart: 'CREATECOPYEART',
  getCopyEarts: 'GETCOPYEARTS',
  getCopyEart: 'GETCOPYEART',
  getUserGalleries: 'GETUSERGALLERIES',
  getUserOwnedArts: 'GETUSEROWNEDARTS',
  getGalleryEarts: 'GETGALLERYEARTS',
  changePrivacy: 'CHANGEPRIVACY',
  uploadGalleryImage: 'UPLOADGALLERYIMAGE',
  getUserProfile: 'GETUSERPROFILE',
  getUserCopyEarts: 'GETUSERCOPYEARTS',
  editGallery: 'EDITGALLERY',
  getGallery: 'GETGALLERY',
  followUser: 'FOLLOWUSER',
  unfollowUser: 'UNFOLLOWUSER',
  followEart: 'FOLLOWEART',
  unfollowEart: 'UNFOLLOWEART',
  getNotifications: 'GETNOTIFICATIONS',
  getNotificationsNotViewed: 'GETNOTIFICATIONSNOTVIEWED',
  createRoom: 'CREATEROOM',
  getRooms: 'GETROOMS',
  getRoom: 'GETROOM',
  getRoomMessages: 'GETROOMMESSAGES',
  getRoomMessagesNotViewed: 'GETROOMMESSAGESNOTVIEWED',
  sendMessage: 'SENDMESSAGE',
  viewNotifications: 'VIEWNOTIFICATIONS',
  getUserActiveSessions: 'GETUSERACTIVESESSIONS',
  getLastMessage: 'GETLASTMESSAGE',
  getNotViewedMessageCount: 'GETNOTVIEWEDMESSAGECOUNT',
  viewAllMessages: 'VIEWALLMESSAGES',
  getMessagesLastPage: 'GETMESSAGESLASTPAGE',
  getMessagePage: 'GETNEXTPAGE',
  roomExists: 'ROOMEXISTS',
  getFollowedUsers: 'GETFOLLOWEDUSERS',
  getFollowedEarts: 'GETFOLLOWEDEARTS',
  getBoughtEarts: 'GETBOUGHTEARTS',
  getForsaleEarts: 'GETFORSALEEARTS',
  changePassword: 'CHANGEPASSWORD',
  copyEartListForSale: "COPYEARTLISTFORSALE",
  copyEartUnlistForSale: "COPYEARTUNLITSTFORSALE",
  copyEartMakePrivate: "COPYEARTMAKEPRIVATE",
  copyEartMakePublic: "COPYEARTMAKEPUBLIC",
  copyEartChangePrice: "COPYEARTCHANGEPRICE",
  changeOwnerCopyEart: "CHANGEOWNERCOPYEART",
  changeCopyEart: "CHANGECOPYEART",
  getAllCopyEarts: "GETALLCOPYEARTS",
  changeOwnerCopyEart: "CHANGEOWNERCOPYEARTS",
  signInWithGoogle: "SIGNINWITHGOOGLE",
  signInWithGoogleCheck: "SIGNINWITHGOOGLECHECK"
};

export default actions;
